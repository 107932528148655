/* set background color and rtl direction */
body{
    background-color:rgba(243, 246, 249, 0.6)!important;
    direction: rtl;
}

/* use on lists to limit their height and add scrollbars */
.list-container {
    max-height: 600px;
    overflow-y: auto;
  }

  /*---old---*/



.box{
    background: linear-gradient(158deg, #09686C -73.61%, #2E405C 55.26%);
    border-radius: 10px;
    border: 0px;
    height: 200px;
    width: 500px;
}

.header{
    font-size: 25px;
    color:gray;
    font-family: Aptos, Arial;
}


.background{
    background: linear-gradient(158deg, #09686C -73.61%, #2E405C 55.26%);
}
  
.show-buildings{
    padding: 20px;
    border-radius: 10px;
    border: 2px solid teal;
    border-color: #008080;
    margin-top: 60px;
}

.buildings-name {
    display: grid;
    grid-template-columns: 2fr 50%;
    /* grid-template-columns: repeat(2, 50in);  */
    grid-gap: 20px;  
    /* padding-top: 40px; */
    padding: 20px;
    
}

.footer-text{
    font-size: 12px;
    color: black;
}

.footer {
    position:fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: center;
    padding: 10px;
}

.button {
    position: relative;
    padding: 10px;
    border-width: 5px;
    border-color:  teal;
    background-color: white;
    border-radius: 20px;
    color: black;
    cursor: pointer;
    width: 90%;
    text-align: right;
    padding-right: 50px;
}

.editBox {
    position: relative;
    padding: 10px;
    border-width: 5px;
    border-color:  teal;
    background-color: white;
    border-radius: 20px;
    color: black;
    cursor: pointer;
    width: 90%;
    text-align: right;
    padding-right: 50px;
}

.button-add{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 20%;
    background-color: teal;
    border-radius: 20px;
    color: white;
    cursor: pointer;
}

.button-edit{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 20%;
    background-color: teal;
    border-radius: 20px;
    color: white;
    cursor: pointer;
}

.button-delete {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 20%;
    background-color: teal;
    border-radius: 20px;
    color: white;
    cursor: pointer;
    margin-right: 10px; /* adjust as needed */
  }

.button:hover {
    background-color: teal;
    color: white;
}

.button-authenticated:hover {
    background-color: teal;
    color: white;
}

.button-nonauthenticated:hover {
    background-color: gray;
    color: white;
}

.home-icon{
    position: absolute;
    top: 50%; 
    right: 0%; 
    transform: translateY(-50%);
    padding: 10px;
    background-color: #008080;
    border-radius: 50px;
    color: white;
}
.search-input{
    margin: 20px 5px;
    text-align: right;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 500px;
    background-color: lightblue;
    padding: 20px;
    direction: rtl;
    text-align: right;
}


/* Style the container of the phone input and country code dropdown */
.phone-container {
    display: flex;
    align-items: center;
}

/* Adjust the spacing between the country code dropdown and phone input */
#country-code {
    margin-right: 10px;
}

.adduser-background {
    
    display: inline-flex;
    width: 95vw;
    padding-top: 10vh;
    padding-bottom: 10vh;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #1A396A 0%, #185B57 100%);
    color: #FFF;
    font-family: Manrope;
    font-size: clamp(2rem, 5vw, 6rem);
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    letter-spacing: 20.593px;
}

.horizontal-container {
    display: inline-flex;
    align-items: center;
    direction:rtl;
    justify-content: center;
}

.adduser-text {
    color: #575555;
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.google-signin-button {
    background-color: #32486A;
    background-image: url('/public/logo-google.svg');
    background-position: 12px 11px;
    background-repeat: no-repeat;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    width: 300px;
    height: 46px;

        font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    cursor: pointer;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    position: relative;
    color: #FFF;
    text-decoration: none;
    text-align: center;
}

.add-user-confirmation-button {
    color: #FFF;
    text-align: center;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    background: #32486A;    

    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.45);
}


.non-editable {
  border: 1px solid #ccc; /* Example border style for non-editable state */
  background-color: #f9f9f9; /* Example background color for non-editable state */
  /* Add other styles for non-editable state */
}

/* Style for editable state */
.editable {
  border: 1px solid #2196f3; /* Example border style for editable state */
  background-color: #ffffff; /* Example background color for editable state */
  /* Add other styles for editable state */
}


/* Style for the file input and drag-drop area */
.drag-drop-area {
  border: 2px dashed #007bff;
  padding: 20px;
  text-align: center;
  margin: 10px 0;
  cursor: pointer;
  background-color: #f8f9fa;
}

.drag-drop-area:hover {
  background-color: #e2e6ea;
}

/* Style for the upload progress bar */
.progress-bar {
  width: 0%;
  height: 20px;
  background-color: #28a745;
  transition: width 0.4s ease;
}

/* Additional styles for uploading and complete states */
.uploading-state,
.upload-complete {
  text-align: center;
  margin-top: 10px;
}

.uploading-state {
  color: #ffc107;
}

.upload-complete {
  color: #28a745;
}